
import type { PropType } from 'vue'
import { defineComponent, ref } from 'vue'
import type { PageContentSidebarItemAction } from '@/definitions/_layout/pageContent/sidebar/types'
import TmSidebarMenuActions from '@/components/layout/pageContent/sidebar/PageContentSidebarItemActions.vue'
import TmEmoji from '@/components/shared/TmEmoji.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import type { RouteLocationRaw } from 'vue-router'
import type { EmojiNameType, FlagCountry } from '@/definitions/shared/types'
import TmFlag from '@/components/shared/TmFlag.vue'

export default defineComponent({
  components: { TmFlag, TmSidebarMenuActions, TmEmoji, TmTooltip },
  props: {
    icon: {
      type: String,
    },
    name: {
      type: String,
    },
    tooltip: {
      type: String,
    },
    tooltipVisible: {
      type: Boolean,
    },
    closable: {
      type: Boolean,
    },
    lightItem: {
      type: Boolean,
    },
    route: {
      type: Object as PropType<RouteLocationRaw>,
    },
    title: {
      type: String,
    },
    number: {
      type: String,
    },
    actions: {
      type: Array as PropType<PageContentSidebarItemAction[]>,
    },
    beta: {
      type: Boolean,
    },
    betaText: {
      type: String,
      default: 'Beta',
    },
    emoji: {
      type: String as PropType<EmojiNameType>,
    },
    flag: {
      type: Object as PropType<FlagCountry>,
    },
    linkIcon: {
      type: Boolean,
    },
  },
  emits: {
    'action-click': (actionName: string, relationId: string) => true,
    'item-click': (name: string) => true,
    close: null,
  },
  setup(props, context) {
    const linkRef = ref()
    const onActionClick = (actionName: string, relationId: string) => {
      context.emit('action-click', actionName, relationId)
    }
    const dropdownState = ref(false)

    return {
      linkRef,
      onActionClick,
      dropdownState,
    }
  },
})
