import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_button = _resolveComponent("tm-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["page-content-sidebar-list-title", {
      'page-content-sidebar-list-title--collapsible': _ctx.collapsible,
    }]),
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
  }, [
    _createElementVNode("span", null, _toDisplayString(_ctx.title), 1),
    (_ctx.showAddButton)
      ? (_openBlock(), _createBlock(_component_tm_button, {
          key: 0,
          "icon-only": "",
          flat: "",
          size: "small",
          color: "transparent",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('add-btn-click')))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_tm_icon, { name: "add" })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.collapsible)
      ? (_openBlock(), _createBlock(_component_tm_icon, {
          key: 1,
          class: "page-content-sidebar-list-title__collapse",
          name: _ctx.collapsed ? 'tmi-expand-more' : 'tmi-expand-less'
        }, null, 8, ["name"]))
      : _createCommentVNode("", true)
  ], 2))
}