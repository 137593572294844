
import type { PropType } from 'vue'
import { defineComponent, ref } from 'vue'
import type { PageContentSidebarItem } from '@/definitions/_layout/pageContent/sidebar/types'
import TmSidebarMenuItem from '@/components/layout/pageContent/sidebar/sidebarItem/PageContentSidebarListItem.vue'

export default defineComponent({
  components: { TmSidebarMenuItem },
  props: {
    items: {
      type: Array as PropType<PageContentSidebarItem[]>,
      required: true,
    },
  },
  emits: {
    'action-click': (actionName: string, relationId: string) => true,
    close: null,
    'item-click': (name: string) => true,
  },
  setup(props, context) {
    const onActionClick = (actionName: string, relationId: string) => {
      context.emit('action-click', actionName, relationId)
    }
    const collapsed = ref(false)

    return {
      onActionClick,
      collapsed,
    }
  },
})
