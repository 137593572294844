import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_tooltip = _resolveComponent("tm-tooltip")!
  const _component_tm_button = _resolveComponent("tm-button")!

  return (_openBlock(), _createBlock(_component_tm_button, {
    size: _ctx.btnSize,
    flat: _ctx.flat,
    "icon-only": "",
    "no-border": _ctx.noBorder,
    color: _ctx.color
  }, {
    default: _withCtx(() => [
      _createVNode(_component_tm_icon, {
        size: _ctx.iconSize,
        class: _normalizeClass(_ctx.iconClass),
        name: _ctx.horizontal ? 'more_horiz' : 'more_vert'
      }, null, 8, ["size", "class", "name"]),
      (_ctx.tooltip)
        ? (_openBlock(), _createBlock(_component_tm_tooltip, _normalizeProps(_mergeProps({ key: 0 }, _ctx.tooltipProps)), {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.tooltip), 1)
            ]),
            _: 1
          }, 16))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["size", "flat", "no-border", "color"]))
}