import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-34353a6a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "menu-item__beta"
}
const _hoisted_2 = {
  key: 4,
  class: "d-flex align-center distinct--text flex-grow-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_emoji = _resolveComponent("tm-emoji")!
  const _component_tm_flag = _resolveComponent("tm-flag")!
  const _component_tm_tooltip = _resolveComponent("tm-tooltip")!
  const _component_tm_sidebar_menu_actions = _resolveComponent("tm-sidebar-menu-actions")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.route ? 'router-link' : 'a'), {
    ref: "linkRef",
    to: _ctx.route,
    "active-class": "active-link",
    class: _normalizeClass(["page-content-sidebar-list-link menu-item", {
      'menu-item--dropdown-active': _ctx.dropdownState,
    }]),
    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('item-click')))
  }, {
    default: _withCtx(() => [
      (_ctx.actions)
        ? (_openBlock(), _createBlock(_component_tm_icon, {
            key: 0,
            name: "drag_indicator",
            class: "menu-item__drag show-on-hover"
          }))
        : _createCommentVNode("", true),
      (_ctx.icon)
        ? (_openBlock(), _createBlock(_component_tm_icon, {
            key: 1,
            class: "mr-2 menu-item__icon",
            name: _ctx.icon,
            size: "large"
          }, null, 8, ["name"]))
        : _createCommentVNode("", true),
      (_ctx.emoji)
        ? (_openBlock(), _createBlock(_component_tm_emoji, {
            key: 2,
            class: _normalizeClass(["mr-2", {
        'opacity-45': _ctx.lightItem,
      }]),
            name: _ctx.emoji,
            size: "small"
          }, null, 8, ["class", "name"]))
        : _createCommentVNode("", true),
      (_ctx.flag)
        ? (_openBlock(), _createBlock(_component_tm_flag, {
            key: 3,
            class: "mr-2",
            country: _ctx.flag,
            size: "xSmall"
          }, null, 8, ["country"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(["menu-item__text", {
        'menu-item__text--light': _ctx.lightItem,
      }])
      }, _toDisplayString(_ctx.title), 3),
      _createElementVNode("div", {
        class: _normalizeClass(["menu-item__counter", {
        'hide-on-hover': _ctx.actions,
        'menu-item__counter--light': _ctx.lightItem,
      }])
      }, [
        (_ctx.number)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(_toDisplayString(_ctx.number), 1)
            ], 64))
          : _createCommentVNode("", true),
        (_ctx.beta)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.betaText), 1))
          : _createCommentVNode("", true),
        (_ctx.tooltip)
          ? (_openBlock(), _createBlock(_component_tm_tooltip, {
              key: 2,
              "model-value": _ctx.tooltipVisible,
              position: "right",
              persistent: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.tooltip), 1)
              ]),
              _: 1
            }, 8, ["model-value"]))
          : _createCommentVNode("", true)
      ], 2),
      (_ctx.linkIcon)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_tm_icon, {
              name: "open_in_new",
              size: "small"
            })
          ]))
        : _createCommentVNode("", true),
      (_ctx.actions)
        ? (_openBlock(), _createBlock(_component_tm_sidebar_menu_actions, {
            key: 5,
            "dropdown-state": _ctx.dropdownState,
            "onUpdate:dropdown-state": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dropdownState) = $event)),
            actions: _ctx.actions,
            class: "show-on-hover",
            onActionClick: _ctx.onActionClick
          }, null, 8, ["dropdown-state", "actions", "onActionClick"]))
        : _createCommentVNode("", true),
      (_ctx.closable)
        ? (_openBlock(), _createBlock(_component_tm_icon, {
            key: 6,
            class: "distinct--text show-on-hover",
            name: "close",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["to", "class"]))
}