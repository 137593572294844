
import { defineComponent } from 'vue'
import PageContentSidebarList from '@/components/layout/pageContent/sidebar/PageContentSidebarList.vue'
import { adminSidebarRoutes } from '@/router/sidebarRoutes/adminRoutes'

export default defineComponent({
  components: { PageContentSidebarList },
  setup() {
    return {
      adminSidebarRoutes,
    }
  },
})
