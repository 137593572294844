import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_sidebar_menu_item = _resolveComponent("tm-sidebar-menu-item")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["page-content-sidebar-list", {
      'page-content-sidebar-list--collapsed': _ctx.collapsed,
    }])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item.title,
        class: _normalizeClass(["page-content-sidebar-list__item", {
        'page-content-sidebar-list__item--title': item.itemType === 'title'
      }])
      }, [
        _createVNode(_component_tm_sidebar_menu_item, _mergeProps(item, {
          collapsed: _ctx.collapsed,
          onActionClick: _ctx.onActionClick,
          onItemClick: ($event: any) => (_ctx.$emit('item-click', item.name)),
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
          onToggleCollapse: _cache[1] || (_cache[1] = ($event: any) => (_ctx.collapsed = !_ctx.collapsed))
        }), null, 16, ["collapsed", "onActionClick", "onItemClick"])
      ], 2))
    }), 128))
  ], 2))
}