
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import type { ButtonSize, IconSize, SizeProp, ButtonColor } from '@/definitions/shared/types'

export default defineComponent({
  components: { TmTooltip, TmButton },
  props: {
    btnSize: {
      type: String as SizeProp<ButtonSize>,
      default: '',
    },
    iconSize: {
      type: String as SizeProp<IconSize>,
      default: 'medium',
    },
    iconClass: {
      type: String,
      default: '',
    },
    flat: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String as PropType<ButtonColor>,
    },
    tooltip: {
      type: String,
      default: '',
    },
    tooltipProps: {
      type: Object,
    },
    horizontal: {
      type: Boolean,
    },
    noBorder: {
      type: Boolean,
    },
  },
})
