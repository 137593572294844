
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  components: { TmButton },
  props: {
    title: {
      type: String,
    },
    showAddButton: {
      type: Boolean,
    },
    collapsible: {
      type: Boolean,
    },
    collapsed: {
      type: Boolean,
    },
  },
  emits: ['add-btn-click', 'toggle-collapse'],
  setup(props, { emit }) {
    const handleClick = () => {
      if (props.collapsible) {
        emit('toggle-collapse')
      }
    }

    return {
      handleClick,
    }
  },
})
