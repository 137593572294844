import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_admin_sidebar = _resolveComponent("admin-sidebar")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(_component_router_view, { "menu-title": "Admin" }, {
    default: _withCtx(({ Component }) => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(Component), null, {
        "filter-bar-slot": _withCtx(() => [
          _createVNode(_component_admin_sidebar)
        ]),
        _: 2
      }, 1024))
    ]),
    _: 1
  }))
}