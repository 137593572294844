
import type { PropType } from 'vue'
import { defineComponent, computed } from 'vue'
import type { PageContentSidebarItemTypes } from '@/definitions/_layout/pageContent/sidebar/types'
import PageContentSidebarListTitle from '@/components/layout/pageContent/sidebar/sidebarItem/PageContentSidebarListTitle.vue'
import PageContentSidebarListLink from '@/components/layout/pageContent/sidebar/sidebarItem/PageContentSidebarListLink.vue'

export default defineComponent({
  components: {
    PageContentSidebarListTitle,
    PageContentSidebarListLink,
  },
  props: {
    itemType: {
      type: String as PropType<PageContentSidebarItemTypes>,
    },
  },
  setup(props) {
    const componentName = computed(() => {
      switch (props.itemType) {
        case 'title': return 'page-content-sidebar-list-title'
        default: return 'page-content-sidebar-list-link'
      }
    })

    return {
      componentName,
    }
  },
})
