
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import type { PageContentSidebarItemAction } from '@/definitions/_layout/pageContent/sidebar/types'
import TmButtonMore from '@/components/shared/TmButtonMore.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'

export default defineComponent({
  components: { TmButtonMore, TmDropdownItem, TmDropdown },
  props: {
    actions: {
      type: Array as PropType<PageContentSidebarItemAction[]>,
      required: true,
    },
    dropdownState: {
      type: Boolean,
    },
  },
  emits: ['action-click', 'update:dropdownState'],
})
