import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, withModifiers as _withModifiers } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_button_more = _resolveComponent("tm-button-more")!
  const _component_tm_dropdown_item = _resolveComponent("tm-dropdown-item")!
  const _component_tm_dropdown = _resolveComponent("tm-dropdown")!

  return (_openBlock(), _createElementBlock("div", {
    class: "page-content-sidebar-item-actions",
    onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop","prevent"]))
  }, [
    _createVNode(_component_tm_dropdown, {
      "model-value": _ctx.dropdownState,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:dropdownState', $event)))
    }, {
      menu: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actions, (action, i) => {
          return (_openBlock(), _createBlock(_component_tm_dropdown_item, {
            key: i,
            icon: action.icon,
            label: action.text,
            onClick: ($event: any) => (_ctx.$emit('action-click', action.name, action.id))
          }, null, 8, ["icon", "label", "onClick"]))
        }), 128))
      ]),
      default: _withCtx(() => [
        _createVNode(_component_tm_button_more, {
          "btn-size": "xSmall",
          horizontal: "",
          color: "transparent"
        })
      ]),
      _: 1
    }, 8, ["model-value"])
  ]))
}